import React, { useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import styles from './ProductCarousel.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'

export default ({ images, title }) => {
  const [mainIndex, setMainIndex] = useState(0)

  const next = () => {
    setMainIndex((mainIndex + 1) % images.length)
  }

  const prev = () => {
    setMainIndex((mainIndex - 1 + images.length) % images.length)
  }

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carouselButtonHolder}>
        <AliceCarousel
          key='alice-carousel-main'
          activeIndex={mainIndex}
          animationType='slide'
          infinite={false}
          animationDuration={500}
          disableDotsControls
          disableButtonsControls
          items={images.map(({ imageId, largeUrl }) => (
            <img
              width='570'
              height='670'
              alt={title}
              key={imageId}
              className={styles.image}
              src={largeUrl}
            />
          ))}
        />
        <button onClick={next} className={styles.next}>
          <FontAwesomeIcon icon={faArrowRight} size='xs' />
        </button>
        <button onClick={prev} className={styles.prev}>
          <FontAwesomeIcon icon={faArrowLeft} size='xs' />
        </button>
      </div>
      <div className={styles.thumbs} key='alice-carousel-thumbs'>
        {images.map(({ imageId, largeUrl }, index) => (
          <button
            key={imageId + '-button'}
            className={styles.thumb}
            onClick={() => setMainIndex(index)}
          >
            <img className alt={title} key={imageId} src={largeUrl} />
          </button>
        ))}
      </div>
    </div>
  )
}
